import { createContext, useState, useContext } from 'react';

const MessageContext = createContext<{
  message: string, 
  addMessage: ( value: string, type: "error" | "warning" | "success" ) => void,
  type: "error" | "warning" | "success"}
>({
  message: "", 
  addMessage: (value: string, type: "error" | "warning" | "success") => {},
  type: "success"
});

export const useMessage = () => {
  return useContext(MessageContext);
};

export const MessageProvider = ({ children }: {children: any}) => {
  const [message, setMessage] = useState<string>("");
  const [type, setType] = useState<"error" | "warning" | "success">("success");

  const addMessage = async (body: string, type: "error" | "warning" | "success") => {
    setMessage(body);
    setType(type)
    
    setTimeout(() => {
      setMessage("")
    }, 5000)
  };

  return (
    <MessageContext.Provider value={{ message, addMessage, type }}>
      {children}
    </MessageContext.Provider>
  );
};