export const DateData = [
    { name: "Domingo", tag: 1 },
    { name: "Segunda-feira", tag: 2 },
    { name: "Terça-feira", tag: 3 },
    { name: "Quarta-feira", tag: 4 },
    { name: "Quinta-feira", tag: 5 },
    { name: "Sexta-feira", tag: 6 },
    { name: "Sábado", tag: 7 },
];

export const StatesData = [
    { name: "Acre", tag: "AC" },
    { name: "Alagoas", tag: "AL" },
    { name: "Amapá", tag: "AP" },
    { name: "Amazonas", tag: "AM" },
    { name: "Bahia", tag: "BA" },
    { name: "Ceará", tag: "CE" },
    { name: "Distrito Federal", tag: "DF" },
    { name: "Espírito Santo", tag: "ES" },
    { name: "Goiás", tag: "GO" },
    { name: "Maranhão", tag: "MA" },
    { name: "Mato Grosso", tag: "MT" },
    { name: "Mato Grosso do Sul", tag: "MS" },
    { name: "Minas Gerais", tag: "MG" },
    { name: "Pará", tag: "PA" },
    { name: "Paraíba", tag: "PB" },
    { name: "Paraná", tag: "PR" },
    { name: "Pernambuco", tag: "PE" },
    { name: "Piauí", tag: "PI" },
    { name: "Rio de Janeiro", tag: "RJ" },
    { name: "Rio Grande do Norte", tag: "RN" },
    { name: "Rio Grande do Sul", tag: "RS" },
    { name: "Rondônia", tag: "RO" },
    { name: "Roraima", tag: "RR" },
    { name: "Santa Catarina", tag: "SC" },
    { name: "São Paulo", tag: "SP" },
    { name: "Sergipe", tag: "SE" },
    { name: "Tocantins", tag: "TO" }
  ];