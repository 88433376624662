import { createContext, useState, useContext } from 'react';
import { API } from '../api';

type userType = {
  cpf: string;
  password: string;
}

type loginResponse = {
  expiresIn: number;
  token: string
}

const AuthContext = createContext<{
  user: loginResponse | null,
  login: (value: userType) => Promise<boolean>,
  logout: () => boolean,
  isLoggedIn: () => Promise<boolean>,
  logged: boolean,
}>({
  user: null,
  login: async (value: userType) => {
    return await new Promise((resolve, reject) => { resolve(true) })
  },
  logout: () => { return true; },
  isLoggedIn: async () => {
    return await new Promise((resolve, reject) => { resolve(true) })
  },
  logged: false,
});

function setCookie(name: string, data: string, hours: number) {
  var expires = "";
  if (hours) {
    var date = new Date();
    date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (data || "") + expires + "; path=/";
}

function getCookie(name: string) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function eraseCookie(name: string) {
  document.cookie = name + '=; Max-Age=-99999999;';
}

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }: { children: any }) => {
  const [user, setUser] = useState<loginResponse | null>(null);
  const [logged, setLogged] = useState<boolean>(false);

  const login = async (userData: userType): Promise<boolean> => {
    const { data } = await API.post("/auth/login", {
      usr_cpf: userData.cpf,
      usr_password: userData.password,
    })

    if (data && data?.token) {
      setUser(data);
      const token = data.token;
      localStorage.setItem("token", token);
    }

    const return_data = data ? true : false;

    return await new Promise((resolve, reject) => { resolve(return_data) })
  };

  const logout = () => {
    setUser(null);

    localStorage.removeItem("token")

    window.location.href = "/";
    return true;
  };

  const isLoggedIn = async (): Promise<boolean> => {
    const token = localStorage.getItem("token");
    const exist = token ? true : false;

    setLogged(exist);

    return await new Promise((resolve, reject) => { resolve(exist) });
  }

  return (
    <AuthContext.Provider value={{ user, login, logout, isLoggedIn, logged }}>
      {children}
    </AuthContext.Provider>
  );
};