// ICONES
import { LoginCardStyled, LoginStyled } from "./styled";

// IMAGES
import logo from "../../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAuth } from "../../../services/contexts/AuthContext";
import { useMessage } from "../../../services/contexts/MessageContext";
import { RemoveCaracteres, formatCPF } from "../../../services/utils/format";

const LoginPage = () => {
  const { addMessage } = useMessage();
  const { login } = useAuth();
  const navigate = useNavigate();
  const [cpf, setCpf] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  function handleNavigateToRegister() {
    navigate('/auth/register')
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    const newCPF = RemoveCaracteres(cpf);
    try {
      await login({ cpf: newCPF, password });
      window.location.href = "/";
    } catch (err) {
      addMessage("CPF ou senha incorreto!", "error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LoginStyled>
      <header></header>
      <section>
        <LoginCardStyled onSubmit={handleSubmit}>
          <div className="boxedImage">
            <img src={logo} alt="" width={100} />
          </div>
          <div className="mt-6">
            <h2 className="text-xl text-slate-800 font-medium">Entrar</h2>
            <p className="text-slate-800 mt-2">Realize o login em sua conta para gerir suas senhas.</p>
          </div>
          <div className="boxed">
            <input
              className="py-3 px-4 border border-[#0082BA] text-slate-700 focus:outline-none rounded-md mt-10 placeholder:text-slate-600"
              type="text"
              placeholder="CPF"
              name="cpf"
              onChange={e => setCpf(formatCPF(e.target.value))} value={cpf}
            />
          </div>
          <div className="boxed">
            <input
              className="py-3 px-4 border border-[#0082BA] text-slate-700 focus:outline-none rounded-md mt-3 placeholder:text-slate-600"
              type="password"
              placeholder="Senha"
              name="password"
              onChange={e => setPassword(e.target.value)} value={password}
            />
          </div>
          <div className="boxed">
            <button
              type="submit"
              className="w-full py-3 bg-[#0082BA] mt-8 rounded-md"
            >
              {isLoading ? (
                <div className="flex justify-center items-center gap-x-3">
                  Entrando
                  <div className="w-5 h-5 border-4 border-white border-t-[#3498db] rounded-full animate-spin" />
                </div>
              ) : 'Entrar'}
            </button>
          </div>
          <div className="boxedOptions">
            <button
              className="w-full py-3 border border-[#0082BA] text-[#0082BA] rounded-md"
              onClick={handleNavigateToRegister}
            >
              Registrar-se
            </button>
            {/* <Link to="/auth/recover" className="link">Esqueci minha senha</Link> */}
          </div>
        </LoginCardStyled>
      </section>
    </LoginStyled>
  )
}

export default LoginPage