import styled from "styled-components";

export const NotExistParagraph = styled("p")`
    color: ${({ theme }) => theme.color.dark};
    text-align: center;
    width: 100%;
    margin-top: 3em;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        margin-right: 10px;
        color: red;
    }
`