import { API } from "../../services/api";

export const getAllPagination = async (page: number, size: number) => {
  const { data } = await API.get(
    `/poster/all/pagination?page=${page}&size=${size}`
  );
  return data;
};
export const getAllFilter = async (
  { state }: { state: string },
  page: number,
  size: number
) => {
  const { data } = await API.post(
    `/poster/all/filter?page=${page}&size=${size}`,
    {
      state,
    }
  );
  return data;
};
