/* eslint-disable react-hooks/exhaustive-deps */
import { CheckoutStyled } from "./styled"

import { ToastContainer, toast } from 'react-toastify';
import { FaRegCopy, FaTicketAlt } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLoading } from "../../services/contexts/LoadingContext";
import { useMessage } from "../../services/contexts/MessageContext";
import { formatSelectDay } from "../../utils/format-select-day";
import { getItem } from "../../services/storage/storage";
import { getOrder } from "../../services/http/payment/get-order";
import { createMultiplePassword } from "../../services/http/passwords/get-all-my";

type checkoutType = {
  pass_id: number,
  pass_number: number,
  pass_value_total: number,
  pass_name_people: string,
  pass_name_people_presentation: string,
  pass_cpf_people: string,
  pass_state_people: string,
  pass_city_people: string,
  pass_code: string,
  pass_status: number,
  pass_createdAt: string,
  pass_updatedAt: string,
  dayId: number,
  day: {
    day_id: number,
    day_index: number,
    day_start_number: number,
    day_end_number: number,
    day_createdAt: string,
    day_updatedAt: string,
    categoryId: number,
  },
  posterId: number,
  categoryId: number,
  quantity_on_passwords: Array<any>,
  category: {
    categ_id: number,
    categ_name: string,
    categ_createdAt: string,
    categ_updatedAt: string,
    postersId: number
  }
  user_id: number
}

type paymentType = {
  pay_id: number,
  pay_key: string,
  pay_method: string,
  pay_code: string,
  pay_qrcode: string,
  pay_createdAt: string,
  pay_updatedAt: string,
  pay_value_total: number,
  pay_status: number,
  passwords: Array<checkoutType>,
  PasswordError: Array<{
    pass_error_id: number,
    pass_error_number: number,
    pass_error_value_total: number,
    pass_error_createdAt: string,
    pass_error_updatedAt: string,
    dayId: number,
    posterId: number,
    categoryId: number,
    user_id: number,
    payment_id: number,
    day: {
      day_id: number,
      day_index: number,
      day_start_number: number,
      day_end_number: number,
      day_createdAt: string,
      day_updatedAt: string,
      categoryId: number,
    },
    category: {
      categ_id: number,
      categ_name: string,
      categ_createdAt: string,
      categ_updatedAt: string,
      postersId: number
    },
  }>
}

const CheckoutPage = () => {
  const { statusLoading } = useLoading();
  const { addMessage } = useMessage();
  const navigate = useNavigate();
  const [copied, setCopied] = useState(false);
  const [verifyingPayment, setVerifyingPayment] = useState(false);

  const paymentData: any = getItem('@pix-payment-response');
  const passwordData: any = getItem('@password-data');

  function notify(msg: string) {
    toast.success(msg);
  }

  function handleCopy() {
    const pixCode = paymentData.qr_codes[0].text;
    navigator.clipboard.writeText(pixCode).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }).catch(err => {
      console.error('Erro ao copiar o código PIX:', err);
    });
  }

  function formattedValue(value: number) {
    return (value / 100).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  async function handleCheckPayment() {
    setVerifyingPayment(true);
    try {
      const response = await getOrder(`${paymentData.id}`);

      if (response) {
        await createMultiplePassword(passwordData);

        notify('Pagamento realizado!');

        setTimeout(() => {
          navigate('/payment-status');
        }, 2000);
      }

      // if (response && response.charges && response.charges[0].status === 'PAID') {
      //   notify('Pagamento realizado!');

      //   await createMultiplePassword(passwordData);

      //   setTimeout(() => {
      //     navigate('/payment-status');
      //   }, 2000);
      // }
      return;
    } catch (error) {
      console.log(error);
    } finally {
      setVerifyingPayment(false);
    }
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      async function checkPayment() {
        try {
          const response = await getOrder(`${paymentData.id}`);

          console.log(response);

          if (response && response.charges && response.charges[0].status === 'PAID') {
            notify('Pagamento realizado!');

            clearInterval(intervalId);

            await createMultiplePassword(passwordData);

            setTimeout(() => {
              navigate('/payment-status');
            }, 2000);
          }
          return;
        } catch (error) {
          console.log(error);
        }
      }

      checkPayment();
    }, 10000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      {
        paymentData && (
          <CheckoutStyled>
            <div className="boxed-informations">
              <div className="boxedPaiment">
                <div className="w-full max-w-[300px] flex flex-col border border-gray-300 rounded-md px-4 py-4">
                  <div className="w-full max-w-[260px]">
                    <img src={paymentData.qr_codes[0].links[0].href} alt="qr_code" />
                  </div>
                  <div className="mt-2">
                    <p className="text-slate-700">
                      Pix copia e cola:
                    </p>
                    <div className="flex items-center gap-x-2 mt-1 px-2 py-1 border rounded-md border-gray-300">
                      <span className="text-slate-700">
                        {`${paymentData.qr_codes[0].text.slice(0, 24)}...`}
                      </span>
                      <div className="hover:cursor-pointer" onClick={handleCopy}>
                        <FaRegCopy className="text-slate-700" />
                      </div>
                    </div>
                    {copied &&
                      <div className="pt-1">
                        <span className="text-[#00DF81]">
                          Copiado!
                        </span>
                      </div>
                    }
                  </div>
                  <div className="mt-3">
                    <button
                      className={`bg-[#00DF81] text-white px-2 py-2 rounded-md transition-all duration-300 ease-in-out ${verifyingPayment ? 'w-[260px]' : 'w-36'}`}
                      onClick={handleCheckPayment}
                    >
                      {verifyingPayment ? 'Verificando pagamento...' : 'Já paguei o pix'}
                    </button>
                  </div>
                </div>
              </div>
              <div className="px-4 mt-4 lg-custom:mt-0">
                <h2 className="text-lg font-medium text-slate-700">
                  Informações da compra
                </h2>
                <div className="flex mt-4">
                  <div className="flex items-center gap-x-2">
                    <p className="text-slate-700">
                      Valor:
                    </p>
                    <div className="flex items-center gap-x-2 bg-[#0082BA] p-2 rounded-md">
                      {formattedValue(paymentData.qr_codes[0].amount.value)}
                    </div>
                  </div>
                </div>

                <div className="flex flex-col sm:flex-row sm:items-center gap-2 mt-4">
                  <p className="text-slate-700">
                    Senhas:
                  </p>
                  <div className="flex flex-wrap items-center gap-2">
                    {
                      passwordData?.passwords && passwordData.passwords.map((item: any, index: any) => (
                        <div className="flex items-center gap-x-2 bg-[#00DF81] p-2 rounded-md">
                          <FaTicketAlt />
                          {item.number} - {item.category.categ_name} - {formatSelectDay(item.day.day_index)}
                        </div>
                      ))
                    }
                  </div>
                </div>

                <div className="mt-4">
                  <h2 className="text-lg font-medium text-slate-700">
                    Informações do comprador
                  </h2>
                  <div className="mt-4">
                    <ul className="flex flex-col gap-y-2 text-slate-700">
                      <li>Nome: <strong>{paymentData.customer.name}</strong></li>
                      <li>E-mail: <strong>{paymentData.customer.email}</strong></li>
                      <li>
                        Telefone: <strong>{`(${paymentData.customer.phones[0].area}) ${paymentData.customer.phones[0].number.slice(0, 5)}-${paymentData.customer.phones[0].number.slice(5)}`}</strong>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="mt-4">
                  <h2 className="text-lg font-medium text-slate-700">
                    Informações adicionais
                  </h2>
                  <div className="mt-2">
                    <span className="text-slate-700 font-medium">
                      Se você utilizou o método de pagamento pix e já realizou o pagamento, clique em <span className="text-[#00DF81]">Já peguei o pix</span>, que iremos verificar o pagamento e liberar a(s) senha(s) imediatamente.
                    </span>
                  </div>
                </div>
                {/* <ul className="observations">
                  <li>FIQUE ATENTO! AS SENHAS NÃO PAGAS ATÉ O VENCIMENTO DO BOLETO SÃO LIBERADAS PARA COMPRA NOVAMENTE.</li>
                  <li>FIQUE ATENTO! AS SENHAS INICIALIZADAS COM CARTÃO DE CRÉDITO, PIX OU PIXEXPRESS QUE NÃO TIVERAM O PAGAMENTO CONFIRMADO SERÃO LIBERADAS PARA COMPRA NOVAMENTE.</li>
                  <li>Você pode realizar o pagamento atravéz de PIX R$ 500,00 + TAXA ADMINISTRATIVA de R$ 3,60.</li>
                  <li>Você pode realizar o pagamento atravéz de PIXEXPRESS R$ 500,00 + TAXA ADMINISTRATIVA de R$ 3,50.</li>
                  <li>Você pode realizar o pagamento atravéz de boleto R$ 500,00 + TAXA ADMINISTRATIVA de R$ 4,70.</li>
                  <li>Se preferir você pode realizar seu pagamento com cartão de crédito no valor de R$ 500,00 + TAXA ADMINISTRATIVA de 7.5% e dividir em até 12x com juros</li>
                  <li>FIQUE ATENTO! EM CASO DE DEVOLUÇÃO, É DEVOLVIDO O VALOR DA SENHA, OS ACRÉSCIMOS SÃO COBRADOS PELO BANCO E O SITE NÃO CONSEGUE DEVOLVER.</li>
                </ul> */}
              </div>
            </div>
          </CheckoutStyled >
        )
      }
      <div className="h-6" />
    </>
  )
}

export default CheckoutPage