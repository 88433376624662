import { CardContentType, TimeType } from "./card.type"

import React, { useEffect, useState } from "react"
import { Info, ButtonLink } from "../../../../assets/css/button"
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { IoLocationOutline } from "react-icons/io5"


export default function Card({
  body: { post_name, post_city, post_date, post_id, post_state, post_capa }
}: CardContentType) {
  const [timeFixed, setTimeFixed] = useState<string>("")
  const [time, setTime] = useState<TimeType>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  })

  function timeRemaining(dateString: string) {
    const targetDate: any = new Date(dateString)
    const currentDate: any = new Date()

    const difference = targetDate - currentDate

    if (difference <= 0) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      }
    }

    const seconds = Math.floor((difference / 1000) % 60)
    const minutes = Math.floor((difference / (1000 * 60)) % 60)
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24)
    const days = Math.floor(difference / (1000 * 60 * 60 * 24))

    return {
      days,
      hours,
      minutes,
      seconds
    }
  }

  function formatDateTime(dateTimeStr: string) {
    const date = new Date(dateTimeStr)

    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()

    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')

    const date_end = `${day}-${month}-${year} ${hours}:${minutes}`
    return date_end
  }

  useEffect(() => {
    console.log(post_date)
    setTimeFixed(formatDateTime(post_date))
    setInterval(() => {
      setTime(timeRemaining(post_date))
    }, 1000)
  }, [])

  return (
    <div className="item-card">
      {/* <div className="capa">
        <img src={post_capa} alt="" />
      </div> */}
      <div className="informations">
        <h2 className="text-2xl text-slate-800 font-medium mb-2">
          {post_name}
        </h2>

        <p className="text-base text-gray-500 font-semibold mb-2">{timeFixed && timeFixed}</p>

        <div className="flex mt-3">
          <div className="flex items-center gap-x-1 mb-2 bg-blue-primary px-3 py-1 rounded-md">
            <IoLocationOutline className="text-xl text-white" />
            <span
              className="font-normal text-[1rem] mt-0.5 text-white"
            >
              {post_city} - {post_state}
            </span>
          </div>
          <div />
        </div>

        {/* <span className="text-slate-600">Vendas de senhas encerram em:</span>
        <div className="flex items-center gap-x-2 mt-1">
          <div>
            <div className="flex justify-center items-center w-10 h-10 rounded-md bg-blue-primary">
              <p className="time">{time.days}</p>
            </div>
            <p className="text-slate-600 text-center mt-1">Dias</p>
          </div>

          <div>
            <div className="flex justify-center items-center w-10 h-10 rounded-md bg-blue-primary">
              <p className="time">{time.hours}</p>
            </div>
            <p className="text-slate-600 text-center mt-1">Horas</p>
          </div>

          <div>
            <div className="flex justify-center items-center w-10 h-10 rounded-md bg-blue-primary">
              <p className="time">{time.minutes}</p>
            </div>
            <p className="text-slate-600 text-center mt-1">Mins</p>
          </div>

          <div>
            <div className="flex justify-center items-center w-10 h-10 rounded-md bg-blue-primary">
              <p className="time">{time.seconds}</p>
            </div>
            <p className="text-slate-600 text-center mt-1">Segs</p>
          </div>
        </div> */}
        <ButtonLink className="btn-sales" backgroundColor="secondary" border="primary" color="secondary" to={`/view/${post_id}`}>Comprar</ButtonLink>
      </div>
    </div >
  )
}