import logo from "../../assets/images/logo.png";

export default function PoliticaDeCancelamentoEReembolsoPage() {
  return (
    <div className="flex flex-col justify-center items-center w-full h-full lg:h-screen bg-zinc-100">
      <div className="w-[80%] my-24 pb-8 shadow-md bg-white rounded-md">
        <div className="flex justify-center items-center -mt-12">
          <img src={logo} alt="" width={138} />
        </div>
        <div className="mt-4 px-12">
          <p>
            Mediante os termos descritos nesta página, a Sua Senha informa aos seus clientes ou visitantes que todas as informações contidas aqui buscam apresentar o máximo de clareza sobre cancelamento de produtos e/ou serviços, bem como reembolso de valores investidos.
          </p>
          <div className="py-2" />
          <strong className="text-lg">
            Política de Cancelamento
          </strong>
          <p className="mt-2">
            • Todo e qualquer vaqueiro reserva-se o direito de solicitar o cancelamento de qualquer serviço ou produto oferecido neste site mediante solicitação enviada a Sua Senha até o encerramento das senhas antecipadas da vaquejada que o mesmo fez a compra da senha.
          </p>
          <p className="mt-2">
            • A MSENHAS faz o estorno total do valor da senha. O VALOR DE ACRÉSCIMO REFERENTE A TAXAS ADMINISTRATIVAS E JUROS DO CARTÃO DE CRÉDITO NÃO É DEVOLVIDO.
          </p>
          <div className="py-2" />
          <strong className="text-lg">
            Política de Reembolso
          </strong>
          <p className="mt-2">
            • O VALOR DE ACRÉSCIMO REFERENTE A TAXAS ADMINISTRATIVAS E JUROS DO CARTÃO DE CRÉDITO NÃO É DEVOLVIDO.
          </p>
          <p className="mt-2">
            • O prazo para a solicitação é até às 18 horas do último dia das vendas pelo site, pois ao encerramento das vendas os valores são repassados para o organizado da vaquejada que você comprou a senha;
          </p>
          <p className="mt-2">
            • A devolução ocorrerá dentro do prazo máximo de até 24 horas contados a partir da solicitação;
          </p>
          <p className="mt-2">
            • Assim que confirmada a devolução do valor, o(s) produto(s) ou serviço(s) serão automaticamente cancelados;
          </p>
          <p className="mt-2">
            • Devoluções de pagamentos realizados por boleto bancário serão estornados na conta bancária registrada no CPF do titular do cadastro ou do vaqueiro, os dados devem ser enviados juntos com a solicitação;
          </p>
          <p className="mt-2">
            • Valores estornados para pagamentos realizados por cartão de crédito podem não ser creditados no mesmo mês, isso dependendo do dia de fechamento da fatura do cliente.
          </p>
        </div>
      </div>
      <div className="flex flex-1 bg-zinc-100">

      </div>
    </div>
  )
}