import { useEffect, useState } from "react"
import { IoLocationOutline } from "react-icons/io5"
import { FiShoppingBag } from "react-icons/fi"
import { formatDateTime } from "../../utils/format-date-time"
import Button from "../Button/button"
import { useNavigate } from "react-router-dom"

type CardProps = {
  post_id: number
  post_capa: string
  post_name: string
  post_city: string
  post_state: string
  post_date: string
  expired: boolean
}

type Time = {
  days: number
  hours: number
  minutes: number
  seconds: number
};

export default function Card({
  post_id,
  post_capa,
  post_name,
  post_city,
  post_state,
  post_date,
  expired
}: CardProps) {
  const navigate = useNavigate()

  const [time, setTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  })

  const timeUnits = [
    { key: 'days', label: 'Dias' },
    { key: 'hours', label: 'Horas' },
    { key: 'minutes', label: 'Mins' },
    { key: 'seconds', label: 'Segs' }
  ]

  function timeRemaining(dateString: string) {
    const targetDate: any = new Date(dateString)
    const currentDate: any = new Date()

    const difference = targetDate - currentDate

    if (difference <= 0) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      }
    }

    const seconds = Math.floor((difference / 1000) % 60)
    const minutes = Math.floor((difference / (1000 * 60)) % 60)
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24)
    const days = Math.floor(difference / (1000 * 60 * 60 * 24))

    return {
      days,
      hours,
      minutes,
      seconds
    }
  }

  const handleNavigation = (post_id: number) => {
    const token = localStorage.getItem("token")

    if (!token) {
      navigate('/auth/login')
      return
    }

    navigate(`/buy-password/${post_id}`)
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(timeRemaining(post_date));
    }, 1000)
    return () => clearInterval(intervalId);
  }, [post_date])

  return (
    <div className="flex w-[340px] rounded-lg p-2 gap-x-4 border border-[#0082BA]">
      <div className="">
        <img
          src={post_capa}
          alt="poster vaquejada"
          className="rounded-lg object-cover h-[190px] w-[9rem]"
        />
      </div>
      <div className="flex flex-col flex-1">

        <div className="flex flex-col flex-1">
          <span
            className="text-slate-700 font-bold"
          >
            {post_name}
          </span>
          <span className="text-slate-500 text-[0.815rem] font-medium">
            {formatDateTime(post_date)}
          </span>
          <div className="flex items-center gap-x-1">
            <IoLocationOutline className="text-blue-primary" />
            <span
              className="font-normal text-[0.815rem] mt-0.5 text-blue-primary"
            >
              {post_city} - {post_state}
            </span>
          </div>

          {!expired ? (
            <>
              <div>
                <span className="text-[0.78rem] text-slate-500">
                  As vendas se encerram em:
                </span>
              </div>

              <div className="flex gap-x-2">
                {timeUnits.map((unit, index) => (
                  <div key={index} className="flex flex-col items-center">
                    <div className="flex items-center justify-center bg-blue-primary w-8 h-8 rounded-md">
                      <span>{time[unit.key as keyof Time]}</span>
                    </div>
                    <span className="text-[0.70rem] text-slate-500">
                      {unit.label}
                    </span>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div className="flex justify-center items-center py-1.5 bg-slate-400 w-full max-w-[8.2rem] rounded-lg mt-2">
              <span className="text-[0.67rem] font-semibold text-white">
                EVENTO EXPIRADO
              </span>
            </div>
          )}
        </div>

        {expired && (
          <Button
            className="w-28 max-w-28"
          >
            <div className="flex justify-center items-center gap-x-2">
              Encerrado
            </div>
          </Button>
        )}

        {!expired && (
          <Button
            className="w-28 max-w-28"
            onClick={() => handleNavigation(post_id)}
          >
            <div className="flex justify-center items-center gap-x-2">
              Comprar <FiShoppingBag className="text-white" />
            </div>
          </Button>
        )}
      </div>
    </div>
  )
}