const Spinner = () => {
  return (
    <div className="flex justify-center items-center mt-10">
      <div
        className="w-10 h-10 border-4 border-gray-200 border-t-[#3498db] rounded-full animate-spin"
      ></div>
    </div>
  );
};

export default Spinner;
