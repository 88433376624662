import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { allPasswordsType } from '../../types/password';
import { DateData } from '../../configs/data';

const styles = StyleSheet.create({
  logo: {
    height: "80px",
    width: "80px",
    marginBottom: "10px",
  },
  page: {
    flexDirection: 'row',
    backgroundColor: '#fff',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  boxtitle: {
    backgroundColor: "#000",
    padding: "10px",
    display: "flex",
    justifyContent: "center",
  },
  title: {
    fontSize: "30px",
    fontWeight: 600,
    color: "#fff",
    textAlign: "center",
  },
  paragraph: {
    fontSize: "18px",
    margin: "10px 0px",
  },
  boxbigText: {
    backgroundColor: "#000",
    padding: "10px",
  },
  bigText: {
    fontSize: "24px",
    fontWeight: 600,
    color: "#fff",
  }
});

const PDFComprovante: React.FC<{
  datas: allPasswordsType
}> = ({
  datas
}) => {
  return (<Document>
    <Page size="A1" style={styles.page}>
      <View style={styles.section}>
        <View style={styles.boxtitle}>
            <Text style={styles.title}>Comprovante de compra</Text>
        </View>
        <Text style={styles.paragraph}>CÓDIGO: {datas?.pass_code}</Text>
        <Text style={styles.paragraph}>NOME: {datas?.pass_name_people}</Text>
        <Text style={styles.paragraph}>CPF: {datas?.pass_cpf_people}</Text>
        <Text style={styles.paragraph}>DIA: {DateData.filter(day => day.tag === datas?.day.day_index)[0].name.toUpperCase()}</Text>
        <Text style={styles.paragraph}>QUANTIDA DE CHANCES: {datas?.quantity_on_passwords.length > 1 ? `${datas?.quantity_on_passwords.length} Chances` : `${datas?.quantity_on_passwords.length} Chance`}</Text>
        <Text style={styles.paragraph}>DATA DA COMPRA: {datas?.pass_createdAt}</Text>
        <Text style={styles.paragraph}>NÚMERO</Text>
        <View style={styles.boxbigText}>
            <Text style={styles.bigText}>{datas?.pass_number}</Text>
        </View>
      </View>
    </Page>
  </Document>
  )
};

export default PDFComprovante;