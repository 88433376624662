import styled from "styled-components";

export const RegisterStyled = styled("article")`
  color: ${({ theme }) => theme.color.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: ${({ theme }) => theme.background.primary};
    padding: 2em 0 8em 0;
  }
  section {
    width: 25%;
  }

  @media (max-width: 1400px) {
    section {
      width: 35%;
    }
  }
  @media (max-width: 1200px) {
    section {
      width: 45%;
    }
  }
  @media (max-width: 900px) {
    section {
      width: 55%;
    }
  }
  @media (max-width: 700px) {
    section {
      width: 65%;
    }
  }
  @media (max-width: 600px) {
    section {
      width: 75%;
    }
  }
  @media (max-width: 500px) {
    section {
      width: 95%;
    }
  }
`;
export const RegisterCardStyled = styled("form")`
  border: solid 1px ${({ theme }) => theme.border.primary_opacy};
  padding: 2em;
  margin-top: -4em;
  background-color: ${({ theme }) => theme.background.secondary};
  border-radius: 10px;
  .boxedImage {
    display: flex;
    justify-content: center;
  }
  .boxed {
    display: flex;
    flex-direction: column;
  }
  .boxedOptions {
    display: flex;
    align-items: center;
    margin-top: 1em;
    .btn {
      padding: 15px 0;
      color: ${({ theme }) => theme.color.secondary};
      border-radius: 100px;
      border: solid 1px ${({ theme }) => theme.border.primary};
      font-size: 18px;
      display: flex;
      width: 100%;
      display: flex;
      justify-content: center;
      font-weight: 600;
    }
    .link {
      padding: 15px 20px;
      text-decoration: underline;
    }
  }

  @media (max-width: 5400px) {
    .boxedOptions {
      display: block;
      .btn {
        margin-bottom: 20px;
      }
    }
  }
`;
