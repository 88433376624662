import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from 'dayjs';
import { getAllEvents as getAllEventsHttp }
  from "../../services/http/events/get-all-events";
import { AllEvents } from "../../types/events.type";
import { FiShoppingBag } from "react-icons/fi";
import { IoLocationOutline } from "react-icons/io5";
import Button from "../../components/Button/button";

const quantityPerPage = 60;

export default function Events() {
  const [events, setEvents] = useState<AllEvents | null>(null);
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();

  async function getAllEvents() {
    try {
      const response = await getAllEventsHttp(currentPage, quantityPerPage);
      console.log(response);
      setEvents(response);
      return
    } catch (error) {
      console.log(error);
    }
  }

  function handleNavigation(post_id: number) {
    const token = localStorage.getItem("token");

    if (!token) {
      navigate('/auth/login');
      return;
    }

    navigate(`/buy-password/${post_id}`);
  }

  useEffect(() => {
    getAllEvents();
  }, []);

  const isEventExpired = (eventDate: string) => {
    return dayjs().isAfter(dayjs(eventDate));
  }

  return (
    <div className="flex justify-center w-full bg-slate-50">
      <div className="w-full max-w-7xl h-screen px-4 py-10 xl:px-0">
        <div>
          <h2 className="text-3xl font-semibold text-center text-blue-primary">
            Vaquejadas
          </h2>
        </div>
        <Button
          onClick={() => navigate('/')}
        >
          <div className="w-full flex justify-center items-center gap-x-2">
            <span className="text-white">Voltar</span>
          </div>
        </Button>
        <div className="flex justify-center items-center">
          <div className="w-[1208px] flex flex-wrap justify-center xl:justify-start gap-y-8 gap-x-8 mt-10">
            {events && events.rows.map((item, index) => (
              <div
                key={index}
                className="flex w-[380px] lg:w-[380px] bg-white shadow-md rounded-md"
              >
                <div className="flex justify-center items-center p-4">
                  <img
                    src={item.post_capa}
                    alt="event banner"
                    className="rounded-md object-cover h-[160px] w-[120px]"
                  />
                </div>
                <div className="flex flex-col py-3 pr-2">
                  <div className="flex flex-col flex-1">
                    <p className="w-full max-w-[180px] text-slate-800 font-medium">
                      {item.post_name}
                    </p>
                    <p className="text-sm text-slate-800 font-light mt-1">
                      {dayjs(item.post_date).format('DD/MM/YYYY')}
                    </p>
                    <div className="flex items-center gap-x-1 mt-1">
                      <IoLocationOutline className="text-blue-primary" />
                      <p className="text-blue-primary">
                        {item.post_city} - {item.post_state}
                      </p>
                    </div>
                  </div>
                  <div className="pb-1">
                    {isEventExpired(item.post_date) ? (
                      <Button
                        className="w-28 max-w-28"
                      >
                        <div className="flex justify-center items-center gap-x-2 text-white">
                          Encerrado
                        </div>
                      </Button>
                    ) : (
                      <Button
                        onClick={() => handleNavigation(item.post_id)}
                        style={{ width: "100%", maxWidth: 175 }}
                      >
                        <div className="w-full flex justify-center items-center gap-x-2">
                          <span className="text-white">Comprar senhas</span>
                          <FiShoppingBag className="text-white" />
                        </div>
                      </Button>
                    )}
                  </div>
                </div>

              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}