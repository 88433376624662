import { ComponentProps, ReactNode } from "react"

type ButtonProps = ComponentProps<'button'> & {
  children: ReactNode
  variants?: 'normal' | 'transparent'
}

const normal = "bg-blue-primary font-medium px-4 py-2 rounded-md"
const transparent = "bg-transparent border border-blue-primary font-medium text-blue-primary px-4 py-2 rounded-md"

export default function Button({
  children,
  variants = 'normal',
  ...rest }: ButtonProps) {

  const className = variants === 'transparent' ? transparent : normal;

  return (
    <button {...rest} className={className}>
      {children}
    </button>
  )
}