import logo from "../../assets/images/logo.png";

export default function ComoFuncionaPage() {
  return (
    <div className="flex flex-col justify-center items-center w-full bg-zinc-100">
      <div className="w-[80%] my-24 pb-8 shadow-md bg-white rounded-md">
        <div className="flex justify-center items-center -mt-12">
          <img src={logo} alt="" width={138} />
        </div>
        <h1 className="text-center text-3xl font-medium text-slate-800 mt-4">
          COMO FAZER MINHA SENHA
        </h1>
        <div className="mt-4 px-12">
          <p>
            Pra fazer sua senha você primeiro precisa fazer o cadastro no site, então clica no botão cadastrar que está no menu do site e em seguida preencha o formulário.
            <div className="py-4" />
            Ao finalizar o cadastro você clica no menu Vaquejadas e escolhe acorrida pra comprar a senha e clica em fazer minha senha. Após realizar esses passos vai aparecer pra você o mapa e os valores das senhas.
            <div className="py-4" />
            Escolha a categoria e o dia que deseja correr, e clique no numero da primeira senha, preencha atentamente os dados do vaqueiro e da senha, so você for realizar a compra de mais de uma senha para o mesmo vaqueiro, você clica no botão fazer outra senha e escolhe o número da próxima senha, e quando finalizar você clica em ESCOHER FORMA DE PAGAMENTO e finalizar pagando a senha com a melhor forma de pagamento pra você.
          </p>
        </div>
        <div className="mt-4 px-12">
          <details>
            <summary className="bg-gray-200 p-4 rounded-md cursor-pointer">
              Como vejo as senhas que reservei?
            </summary>
            <div className="bg-gray-100 p-4 mt-2 rounded-md">
              <p className="text-gray-600">
                Você clica no menu minhas senhas, e preenche o login com o email ou telofone e a senha cadastrada no site.
              </p>
            </div>
          </details>
        </div>
        <div className="mt-4 px-12">
          <details>
            <summary className="bg-gray-200 p-4 rounded-md cursor-pointer">
              Como faço pra mudar os dados da senha?
            </summary>
            <div className="bg-gray-100 p-4 mt-2 rounded-md">
              <p className="text-gray-600">
                Após a senha feita, você pode alterar os dados dela, basta clicar no menu minhas senhas e em seguida no botão EDITAR SENHA.
              </p>
            </div>
          </details>
        </div>
        <div className="mt-4 px-12">
          <details>
            <summary className="bg-gray-200 p-4 rounded-md cursor-pointer">
              Quanto tempo tenho para pagar minha senha?
            </summary>
            <div className="bg-gray-100 p-4 mt-2 rounded-md">
              <p className="text-gray-600">
                Quando o boleto é gerado o vaqueiro tem até o vencimento para realizar o pagamento. Caso o vaqueiro faça apenas a reserva do número o sistema libera a senha no dia seguinte.
              </p>
            </div>
          </details>
        </div>
        <div className="mt-4 px-12">
          <details>
            <summary className="bg-gray-200 p-4 rounded-md cursor-pointer">
              Como faço o pagamento?
            </summary>
            <div className="bg-gray-100 p-4 mt-2 rounded-md">
              <p className="text-gray-600">
                O site Sua Senha oferece três formas de pagamento, cartão de crédito, boleto bancário ou PIX. FIQUE ATENTO AO VENCIMENTO DO BOLETO.
              </p>
            </div>
          </details>
        </div>
        <div className="mt-4 px-12">
          <details>
            <summary className="bg-gray-200 p-4 rounded-md cursor-pointer">
              Vou fazer mais de uma senha, posso pagar com cartão de crédito e boleto bancário?
            </summary>
            <div className="bg-gray-100 p-4 mt-2 rounded-md">
              <p className="text-gray-600">
                Quando o vaqueiro compra mais de uma senha, ele pode dividir o pagamento entre cartão de crédito e boleto bancário. Na primeira senha ele paga com cartao e gera o boleto da segunda senha.
                Exemplo: Vai fazer 2 senhas, a primeira ele compra com cartão de crédito e segunda com boleto bancário.
              </p>
            </div>
          </details>
        </div>
        <div className="mt-4 px-12">
          <details>
            <summary className="bg-gray-200 p-4 rounded-md cursor-pointer">
              Posso pagar com cartão de crédito?
            </summary>
            <div className="bg-gray-100 p-4 mt-2 rounded-md">
              <p className="text-gray-600">
                Pode sim! E ainda parcela em até 12x com juros. Usando o cartão de crédito sua compra é aprovada em até 3 segundos.
              </p>
            </div>
          </details>
        </div>
        <div className="mt-4 px-12">
          <details>
            <summary className="bg-gray-200 p-4 rounded-md cursor-pointer">
              O cartão de crédito tem acrécimo?
            </summary>
            <div className="bg-gray-100 p-4 mt-2 rounded-md">
              <p className="text-gray-600">
                Pagamento com cartão de crédito é acrescentado a taxa administrativa da operadora, juntamente com o juros do parcelamento. Para entender visualizar o valor das parcelas, acessa o menu vaquejadas, clique na corrida que deseja consultar e ao chegar o mapa de senhas clique no botão FORMAS DE PAGAMENTO.
                EM CASO DE DEVOLUÇÕES É DEVOLVIDO O VALOR DA SENHA
              </p>
            </div>
          </details>
        </div>
        <div className="mt-4 px-12">
          <details>
            <summary className="bg-gray-200 p-4 rounded-md cursor-pointer">
              Posso comprar senhas para outro vaqueiros no meu cadastro?
            </summary>
            <div className="bg-gray-100 p-4 mt-2 rounded-md">
              <p className="text-gray-600">
                Pode sim! Quando for preencher os dados do vaqueiro na senha você coloca o CPF, Nome completo, apelido, cidade e estado do outro competidor.
              </p>
            </div>
          </details>
        </div>
        <div className="mt-4 px-12">
          <details>
            <summary className="bg-gray-200 p-4 rounded-md cursor-pointer">
              Quanto tempo tenho para pagar meu boleto ou a chave PIX?
            </summary>
            <div className="bg-gray-100 p-4 mt-2 rounded-md">
              <p className="text-gray-600">
                O prazo de pagamento é um dia util, desde que não seja ultimo dia de senhas antecipadas da vaquejada. Se for ultimo dia o prazo é o mesmo dia PAGAMENTOS PAGO APÓS O VENCIMENTO NÃO CONFIRMAM A NUMERAÇÃO DA SENHA.
              </p>
            </div>
          </details>
        </div>
        <div className="mt-4 px-12">
          <details>
            <summary className="bg-gray-200 p-4 rounded-md cursor-pointer">
              Não paguei o boleto da minha senha! E agora, o que faço?
            </summary>
            <div className="bg-gray-100 p-4 mt-2 rounded-md">
              <p className="text-gray-600">
                Não se preocupe! Caso você não realize o pagamento do boleto sua senha será liberada e a numeração ficará disponível no mapa para uma nova compra.
              </p>
            </div>
          </details>
        </div>
        <div className="mt-4 px-12">
          <details>
            <summary className="bg-gray-200 p-4 rounded-md cursor-pointer">
              Posso cancelar minha(as) senha(as) e receber o reembolso?
            </summary>
            <div className="bg-gray-100 p-4 mt-2 rounded-md">
              <p className="text-gray-600">
                Pode sim! Você tem até às 18 horas do último dia das vendas antecipadas para entrar em contato conosco solicitando o cancelamento das senhas e o reembolso. O reembolso com cartão de crédito é realizado na fatura do cartão e pagamentos com boleto bancário o rembolso é realizado na conta bancária do titular do cadastro onde foi comprado as senhas.
                EM CASO DE DEVOLUÇÕES É DEVOLVIDO O VALOR DA SENHA
              </p>
            </div>
          </details>
        </div>
      </div>
      <div className="flex flex-1 bg-zinc-100" />
    </div>
  )
}