import styled from "styled-components";

export const ViewStyled = styled("div")`
  border: solid 1px ${({ theme }) => theme.border.primary_opacy};
  padding: 2em;
  margin-top: -4em;
  background-color: ${({ theme }) => theme.background.secondary};
  border-radius: 10px;
  .item-card {
    border: none;
    .capa {
      width: auto;
      img {
        margin: 0px;
      }
    }
    .informations {
      .btn-sales {
        display: none;
      }
    }
  }
  .filters {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1em;
    .boxed {
      p {
        color: ${({ theme }) => theme.color.primary2};
        padding: 5px;
      }
      select {
        padding: 10px;
        font-size: 18px;
        border-radius: 10px;
        width: 280px;
        margin-right: 20px;
        border: solid 1px ${({ theme }) => theme.border.primary_opacy};
        &:focus {
          outline: none;
        }
      }
    }
  }
  .info {
    margin-top: 2em;
    p {
      color: ${({ theme }) => theme.color.primary2};
      padding: 5px;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        display: flex;
        align-items: center;
        margin: 5px;
        span {
          width: 20px;
          height: 20px;
          display: flex;
          border-radius: 5px;
          margin-right: 7px;
        }
      }
    }
  }
  .passwords {
    margin-top: 2em;
    p {
      color: ${({ theme }) => theme.color.primary2};
      padding: 5px;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 5px;
        border: solid 1px ${({ theme }) => theme.border.primary2};
        padding: 20px;
        border-radius: 10px;
        svg {
          font-size: 30px;
        }
        p {
          margin: 0 10px;
        }
      }
    }
  }
  .numbers {
    margin-top: 2em;
    display: flex;
    flex-wrap: wrap;
    li {
      border: solid 1px ${({ theme }) => theme.border.primary};
      border-radius: 10px;
      margin: 7px;
      color: ${({ theme }) => theme.color.secondary};
      padding: 8px 0px;
      font-weight: 500;
      text-align: center;
      width: 52px;
      cursor: pointer;
      &:hover {
        background-color: ${({ theme }) => theme.background.secondary3};
      }
      &.selected {
        background-color: ${({ theme }) => theme.background.primary};
        color: ${({ theme }) => theme.color.primary};
      }
      &.danger {
        background-color: red;
        border: solid 1px red;
        color: white;
        cursor: not-allowed;
      }
    }
  }
  .values {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 2em;
    p {
      color: ${({ theme }) => theme.color.dark};
      font-size: 18px;
      border-bottom: solid 1px ${({ theme }) => theme.border.primary_opacy};
      margin-bottom: 10px;
      width: 50%;
      text-align: end;
      padding: 5px 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      span {
        margin-left: 10px;
        background-color: ${({ theme }) => theme.background.danger};
        color: ${({ theme }) => theme.color.primary};
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        padding: 5px;
        cursor: pointer;
      }
    }
    h2 {
      font-size: 26px;
      color: ${({ theme }) => theme.color.dark};
      font-weight: 600;
      margin-top: 5px;
    }
  }
  .popupChances {
    width: 100vw;
    height: 100vh;
    background-color: #4040401f;
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .card {
      background-color: white;
      padding: 20px;
      border-radius: 10px;
      z-index: 10;
      p {
        color: ${({ theme }) => theme.color.primary2};
        padding: 5px;
      }
      select {
        padding: 10px;
        font-size: 18px;
        border-radius: 10px;
        width: 280px;
        border: solid 1px ${({ theme }) => theme.border.primary_opacy};
        &:focus {
          outline: none;
        }
      }
      .btns {
        display: flex;
        margin-top: 10px;
        button {
          padding: 10px 30px;
          border: none;
          font-size: 20px;
          color: ${({ theme }) => theme.color.primary};
          font-weight: 600;
          letter-spacing: 1px;
          border-radius: 5px;
          cursor: pointer;
          &:nth-child(1) {
            margin-right: 5px;
            background-color: ${({ theme }) => theme.background.danger};
          }
          &:nth-child(2) {
            margin-left: 5px;
            background-color: ${({ theme }) => theme.background.success};
          }
        }
      }
    }
  }
  .form {
    margin-top: 2em;
    .inputBox {
      display: flex;
      flex-direction: column;
      p {
        color: ${({ theme }) => theme.color.dark};
        margin-top: 1em;
      }
      input,
      span {
        color: red;
      }
    }
  }
`;
