export function validateEmail(email: string) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
}

export function completeMinLength(value: string, quant: number) {
    if(value.length < quant){
        return false;
    } else {
        return true;
    }
}

export function compareText(text1: string, text2: string) {
    if(text1 === text2){
        return true;
    } else {
        return false;
    }
}

export function required(value: string) {
    if(`${value}`.trim()){
        return true;
    } else {
        return false;
    }
}