import { MessageComponent } from "./styled";

const TimedMessage: React.FC<{
  message: string;
  type: "error" | "warning" | "success"
}> = ({ message, type}) => {
  return (
    <MessageComponent typeColor={type}>
      <p>
        {message}
      </p>
    </MessageComponent>
  );
};

export default TimedMessage;