import styled from 'styled-components';

export const LoadingComponent = styled("div")`
  position: fixed;
  background-color: #eee;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  img {
    height: 180px;
  }
  .loading {
    height: 30px;
    margin-top: 1em;
  }
`;