import logo from "../../assets/images/logo.png";

export default function QuemSomosPage() {
  return (
    <div className="flex flex-col justify-center items-center w-full h-full sm:h-screen bg-zinc-100">
      <div className="w-[80%] my-24 pb-8 shadow-md bg-white rounded-md">
        <div className="flex justify-center items-center -mt-12">
          <img src={logo} alt="" width={138} />
        </div>
        <h1 className="text-center text-3xl font-medium text-slate-800 mt-4">
          QUEM SOMOS
        </h1>
        <div className="mt-4 px-12">

          <p>
            A MSENHAS é uma empresa especialista e dedicada a venda de senhas antecipadas para vaquejada de qualquer porte.
            <div className="py-4" />
            Somos o site de senhas antecipadas mais completo do mercado. Nosso sistema dispõe de todas as funcionalidades que o vaqueiro precisa para compra, acompanhar e alterar os dados de suas senhas. Completo também para o organizador do evento que pode acompanhar as informações de sua vaquejada em tempo real de forma rápida e segura.
            <div className="py-4" />
            A MSENHAS não realiza vaquejadas, somos uma empresa terceirizada, contratada pelo o organizador do evento para realizar as vendas de senhas antecipadas antes da data marcada para o evento. Dessa forma nossa participação no evento está limitada as vendas de senhas antecipadas pelo site e por esse motivo não realizamos a secretaria durante a vaquejada.
          </p>
        </div>
      </div>
      <div className="flex flex-1 bg-zinc-100">

      </div>
    </div>
  )
}