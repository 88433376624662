export function formatCPF(cpf: string) {
    const model = "000.000.000-00";

    if((cpf.length - 1) < model.length){
        cpf = cpf.replace(/\D/g, '');
        cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
        cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
        cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
        return cpf;
    } else {
        return cpf.slice(0, -1);
    }
}

export function RemoveCaracteres(value: string) {
    return value.replace(/\D/g, '')
}

export function formatPhone(phone: string) {
    const model = "(00) 00000-0000";

    if(phone.length < model.length){
        phone = phone.replace(/\D/g, '');
        phone = phone.replace(/^(\d{2})(\d)/g, '($1) $2');
        phone = phone.replace(/(\d{5})(\d{4})$/, '$1-$2');
        return phone;
    } else {
        return phone.slice(0, -1);
    }
}