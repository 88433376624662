import styled from "styled-components";

export const HomeHeaderH1Styled = styled("h1")`
  font-size: 45px;
  text-align: center;
  padding: 1em 0 0 0;
  font-weight: bold;

  @media (max-width: 500px) {
    font-size: 40px;
  }
  @media (max-width: 400px) {
    font-size: 30px;
  }
`;

export const HomeHeaderPagraphStyled = styled("p")`
  font-size: 24px;
  padding: 1.5em 0 1.5em 0;
  text-align: center;
  @media (max-width: 500px) {
    padding: 1.5em 2em 2em 2em;
  }
  @media (max-width: 500px) {
    font-size: 20px;
  }
`;

export const HomeHeaderActionsStyled = styled("div")`
  display: flex;
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
    a {
      margin-bottom: 1em;
    }
  }
`;

export const HomeListStyled = styled("ul")`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  padding: 2em 0;
  grid-gap: 30px;
  margin: 1em;

  @media (max-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ControllerStyled = styled("ul")`
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
  h2 {
    color: ${({ theme }) => theme.color.secondary};
    font-weight: 500;
  }

  @media (max-width: 450px) {
    flex-direction: column;
    select {
      margin-top: 0.5em;
    }
  }
`;
