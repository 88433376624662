import { createContext, useState, useContext } from 'react';

const LoadingContext = createContext<{
  load: boolean,
  statusLoading: (load: boolean) => void
}>({
  load: false,
  statusLoading: (load: boolean) => {}
});

export const useLoading = () => {
  return useContext(LoadingContext);
};

export const LoagingProvider = ({ children }: {children: any}) => {
  const [load, setLoad] = useState<boolean>(false);

  const statusLoading = async (loadParam: boolean) => {
    setLoad(loadParam)
  };

  return (
    <LoadingContext.Provider value={{ load, statusLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};