import logo from "../../assets/images/logo.png";

export default function PoliticaDePrivacidadePage() {
  return (
    <div className="flex flex-col justify-center items-center w-full h-full sm:h-screen bg-zinc-100">
      <div className="w-[80%] my-24 pb-8 shadow-md bg-white rounded-md">
        <div className="flex justify-center items-center -mt-12">
          <img src={logo} alt="" width={138} />
        </div>
        <h1 className="text-center text-3xl font-medium text-slate-800 mt-4">
          POLÍTICA DE PRIVACIDADE MSENHAS
        </h1>
        {/* <div className="mt-4 px-12">
          <p>
            Olá, usuário!
            <div className="py-3" />
            Seja bem-vindo a MSENHAS! A Política de Privacidade a seguir é baseada em nossos princípios e valores. Levamos muito a sério sua privacidade e, por isso, protegemos seus dados pessoais.
            <div className="py-3" />
            Durante a utilização da MSENHAS, os dados pessoais dos usuários poderão ser coletados, como por exemplo, ao criar uma conta ou se inscrever em um evento. Podem também ser coletados “dados não pessoais”. Para cada uma dessas informações coletadas, existe um nível de proteção adequado que adotamos, assim como diferentes tipos de utilização.
            <div className="py-3" />
            Como condição para acesso e utilização da Sua Senha e suas funcionalidades, você declara que realizou a leitura completa e atenta das regras deste documento, estando plenamente ciente.
            <div className="py-3" />
            <strong>
              1. Glossário
            </strong>
            <div className="py-3" />
            Vaqueiro: termo utilizado para designar pessoas que adquirem senhas para as vaquejadas cadastradas no site MSENHAS.
            <div className="py-3" />
            Cookies: arquivos que têm a finalidade de identificar um computador e obter dados de acesso, como páginas navegadas ou links clicados, permitindo, desta forma, personalizar a navegação destes, de acordo com o perfil do usuário.
            <div className="py-3" />
            Dado Pessoal: informação relacionada a pessoa natural identificada ou identificável.
            <div className="py-3" />
            Dado Não Pessoal: quaisquer informações que não se relacionem com uma pessoa e/ou não possam ser usados para identificar uma pessoa.
            <div className="py-3" />
            Organizador: termo utilizado para designar pessoas físicas que contrataram a MSENHAS para realizar a venda de senhas antecipadas para sua vaquejada.
            <div className="py-3" />
            Usuário: termo utilizado para designar pessoas que fazem o cadastro no site para comprar as senhas. Caso o usuário adquira as senhas em nome próprio será também Vaqueiro. Por outro lado, caso o Usuário adquira senha em proveito de terceiros, estes serão considerados tão somente Usuário, e não vaqueiro.
            <div className="py-3" />
            Site MSENHAS: designa a plataforma tecnológica disponível para organizadores de vaquejadas, facilitando a venda de senhas antecipadas e a gestão de participantes, acessível no endereço eletrônico www.msenhas.com.br;
          </p>
        </div> */}
      </div>
      <div className="flex flex-1 bg-zinc-100">

      </div>
    </div>
  )
}