import { API } from "../../services/api";
import { CreateMultiplePasswordType } from "../../types/password";

export const getOne = async (id: number | string) => {
  const { data } = await API.get(`/poster/${id}`);
  return data;
};

export const createMultiplePassword = async (
  body: CreateMultiplePasswordType
) => {
  const { data } = await API.post(`/password/create/multiple`, body as any);
  return data;
};

export const addPasswordInFile = async (body: CreateMultiplePasswordType) => {
  const { data } = await API.post(`/queue/passwords`, body as any);
  return data;
};

export const addPasswordRemoveFile = async (id: number | string) => {
  const { data } = await API.get(`/queue/status/${id}`);
  return data;
};

export const getPaymentComplet = async (joinId: number | string) => {
  const { data } = await API.get(`/payment/join-complete/${joinId}`);
  return data;
};

export const createPayment = async (body: any) => {
  const { data } = await API.post("/payment", body);
  return data;
};
