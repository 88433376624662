import styled from "styled-components";


export const Pagination = styled("div")`
    display: flex;
    justify-content: space-between;
    margin-top: 1em;
    ul {
        display: flex;
    }
`;
export const PaginationInfo = styled("p")`
    color: ${(item) => item.theme.color.dark};
`;
type PaginationItemType = {
    active: boolean
}
export const PaginationItem = styled("li")<PaginationItemType>`
    color: ${({theme}) => theme.color.dark};
    border: solid 1px ${(item) => item.theme.border.primary};
    width: 30px;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    border-radius: 10px;
    background-color: ${(item) => item.active ? item.theme.background.primary : item.theme.background.secondary};
    color: ${(item) => item.active ? item.theme.background.secondary : item.theme.background.primary};
    margin: 0 5px;
    cursor: pointer;
    &:hover {
        background-color: ${(item) => !item.active && item.theme.background.secondary3};
    }
    p {
        font-size: 16px;
        font-weight: 600;
    }
`;