import styled from 'styled-components';

type MessageType = {
  typeColor: "error" | "warning" | "success"
}
export const MessageComponent = styled("div")<MessageType>`
  position: fixed;
  padding: 20px;
  background-color: ${props => {
    if(props.typeColor === "error"){
      return "#FF0000";
    } else if(props.typeColor === "success"){
      return "#39FF14";
    } else if(props.typeColor === "warning"){
      return "#FFFF00";
    } else {
      return "#FF0000"
    }
  }};
  z-index: 1;
  top: 10px;
  right: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  span {
    margin-right: 10px;
    color: white;
    padding: 5px;
    cursor: pointer;
    transition: 0.3s;
    font-size: 20px;
    &:hover {
      background-color: ${props => {
        if(props.typeColor === "error"){
          return "#ff6262";
        } else if(props.typeColor === "success"){
          return "#39FF14";
        } else if(props.typeColor === "warning"){
          return "#FFFF00";
        } else {
          return "#ff6262";
        }
      }};
      transition: 0.3s;
      border-radius: 10px;
    }
  }
  p {
    color: ${props => {
        if(props.typeColor === "error"){
          return "#fff";
        } else if(props.typeColor === "success"){
          return "#000";
        } else if(props.typeColor === "warning"){
          return "#000";
        } else {
          return "#fff";
        }
      }} !important;
    font-size: 18px;
    margin: 0px;
  }
`;