export const theme = {
    color: {
      primary: '#fff',
      primary2: '#555',
      primary3: '#777',
      secondary: '#0082BA',
      dark: '#000',
      light: '#fff'
    },
    background: {
      primary: '#0082BA',
      danger: '#FF0000',
      secondary: '#fff',
      secondary2: '#f1f1f1',
      secondary3: '#eee',
      secondary4: '#ddd',
      success: "#00DF81",
      thred: "#fff",
      dark: "#000",
      opacity: "#0000",
    },
    border: {
      primary: '#0082BA',
      primary_opacy: '#ace6ff',
      primary2: '#555',
      opacity: '#c9c9c9',
      secondary: '#fff',
      success: "#00DF81"
    },
    width: {
      primary: "65%",
      secondary: "30%"
    }
};