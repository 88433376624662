import { FaCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/button";

export default function PaymentStatus() {
  const navigate = useNavigate();

  return (
    <div className="w-full h-screen flex flex-col justify-center items-center gap-y-4 bg-zinc-100">
      <div className="flex flex-col justify-center items-center w-[330px] sm:w-[440px] py-10 gap-y-6 bg-white rounded-md shadow-md">
        <FaCheckCircle className="text-3xl sm:text-5xl font-semibold text-green-400" />
        <h2 className="text-1xl sm:text-2xl font-semibold text-green-400">
          Pagamento realizado com sucesso!
        </h2>
      </div>
      <Button onClick={() => navigate('/my-passwords')}>
        <p className="text-white">Ir para minhas senhas</p>
      </Button>
    </div>
  )
}