import styled from "styled-components";

type LayoutStyledType = {
  header: boolean;
};
export const LayoutStyled = styled("article")<LayoutStyledType>`
  color: ${({ theme }) => theme.color.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  position: relative;
  header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: ${({ theme }) => theme.background.primary};
    padding: ${(props) => (props.header ? "2em 0 4em 0" : "2em 0 8em 0")};
  }
  section {
    width: 65%;
  }

  @media (max-width: 1600px) {
    section {
      width: 85%;
    }
  }
  @media (max-width: 1300px) {
    section {
      width: 95%;
    }
  }
`;
