import './index.css';
import ReactDOM from 'react-dom/client';
import App from './App';
import '@splidejs/react-splide/css';
import { BrowserRouter } from "react-router-dom";
import { GlobalComponent } from './assets/css/global';
import { ThemeProvider } from 'styled-components';
import { theme } from './configs/theme';
import { AuthProvider } from './services/contexts/AuthContext';
import { MessageProvider } from './services/contexts/MessageContext';
import { LoagingProvider } from './services/contexts/LoadingContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <GlobalComponent />
      <LoagingProvider>
        <MessageProvider>
          <AuthProvider>
            <App />
          </AuthProvider>
        </MessageProvider>
      </LoagingProvider>
    </ThemeProvider>
  </BrowserRouter>
);
