import { useEffect, useState } from "react";
import { FaCreditCard } from "react-icons/fa";
import { FaPix } from "react-icons/fa6";
import { getEvent } from "../../services/http/events/get-event";
import Spinner from "../../components/Spinner/spinner";
import { formatSelectDay } from "../../utils/format-select-day";
import { formatCurrency } from "../../utils/format-currency";
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from "react-router-dom";
import { createPaymentPix } from "../../services/http/payment/create-payment-pix";
import { getMeProfile } from "../../services/http/users/get-me-profile";
import { getItem, setItem } from "../../services/storage/storage";
import { encryptCardPagSeguro } from "../../services/pag-seguro/encrypt";

export default function ResumePage() {
  const [eventData, setEventData] = useState<any>(null);
  const [profile, setProfile] = useState<any>(null);
  const [quantities, setQuantities] = useState<any[]>([])
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState<number>();
  const navigate = useNavigate();

  const passwordData: any = getItem('@password-data');

  function calculateTotal(quantity: Array<{ quant_id: number, quant_value: number }>) {
    let value = 0;

    quantity.forEach(element => {
      value += element.quant_value
    });

    setTotal(value);
  }

  async function getData() {
    setLoading(true);
    try {
      const response = await getEvent(passwordData.passwords[0].posterId!);
      setEventData(response);
      return
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function getProfile() {
    setLoading(true);
    try {
      const response = await getMeProfile()
      setProfile(response);
      return
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleCreatePaymentPix() {
    const area = profile?.usr_phone.substring(0, 2);
    const number = profile?.usr_phone.substring(2);
    const body = {
      customer: {
        name: profile?.usr_name,
        email: profile?.usr_email,
        tax_id: profile?.usr_cpf,
        phones: [{
          country: '55',
          area,
          number,
          type: 'MOBILE'
        }]
      },
      items: quantities.map((item, index) => ({
        reference_id: uuidv4(),
        name: item.quant_name,
        quantity: 1,
        unit_amount: item.quant_value * 100
      })),
      qr_codes: [
        {
          amount: {
            value: total && total * 100
          }
        }
      ],
      shipping: {
        address: {
          street: passwordData.address.pass_street_people,
          number: passwordData.address.pass_number_people,
          complement: passwordData.address.pass_complement_people,
          locality: passwordData.address.pass_locality_people,
          city: passwordData.address.pass_city_people,
          region_code: passwordData.address.pass_state_people,
          country: passwordData.address.pass_country_people,
          postal_code: passwordData.address.pass_postal_code_people
        }
      },
      notification_urls: ["https://seusite.com/notificacoes"]
    }
    try {
      const response = await createPaymentPix(body as any);
      setItem('@pix-payment-response', response);
      navigate('/checkout');
      return;
    } catch (error) {
      console.log('Error checking job:', error);
      return false;
    }
  }

  async function handledEncryptionCard() {
    const cardData = {
      holder: {
        name: "José Farias",
      },
      number: "5240082975622454",
      expMonth: 12,
      expYear: 2026,
      securityCode: "123"
    };

    console.log(cardData);

    try {
      const encryptedCard = await encryptCardPagSeguro(cardData);
      console.log('Cartão criptografado:', encryptedCard);
    } catch (error) {
      console.error('Erro no processamento do pagamento:', error);
    }
  }

  useEffect(() => {
    if (passwordData) {
      getData();
      getProfile();
      const quantities = passwordData.passwords.flatMap((p: any) =>
        p.quantity.map((q: any) => q)
      );
      setQuantities(quantities);
      calculateTotal(quantities);
    }
  }, []);

  return (
    <div className="border border-[#ace6ff] bg-white -mt-[4em] rounded-[10px] p-4">
      {loading ? (
        <div className="mb-[2em]">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="flex items-center gap-x-2">
            <span className="text-xl font-medium text-slate-800">
              Resumo do pedido
            </span>
          </div>
          <div className="w-full h-[1px] bg-zinc-300 mt-5" />
          <div className="mt-4">
            <span className="text-slate-800">
              Suas senhas para:{' '}
              <strong className="text-blue-primary">{eventData?.post_name}</strong>
            </span>
          </div>
          <div className="flex flex-col gap-y-4 mt-4">
            {passwordData?.passwords.map((item: any, index: any) => (
              <div key={index} className="border border-[#ace6ff] p-3 rounded-md">
                <div className="flex items-center">
                  <span className="text-slate-800 font-medium">
                    Senha:
                  </span>
                  <span className="text-slate-800 ml-1">
                    Número {item.number} - {formatSelectDay(item.day.day_index)}
                  </span>
                </div>
                <div className="flex items-center">
                  <span className="text-slate-800 font-medium">
                    Vaqueiro:
                  </span>
                  <span className="text-slate-800 ml-1">
                    {passwordData.pass_name_people}
                  </span>
                </div>
                <div className="flex items-center">
                  <span className="text-slate-800 font-medium">
                    Cavalo puxador:
                  </span>
                  <span className="text-slate-800 ml-1">
                    {passwordData.pulling_horse}
                  </span>
                </div>
                <div className="flex items-center">
                  <span className="text-slate-800 font-medium">
                    Esteireiro:
                  </span>
                  <span className="text-slate-800 ml-1">
                    {passwordData.steerer}
                  </span>
                </div>
                <div className="flex items-center">
                  <span className="text-slate-800 font-medium">
                    Cavalo esteireiro:
                  </span>
                  <span className="text-slate-800 ml-1">
                    {passwordData.steer_horse}
                  </span>
                </div>
                <div className="flex items-center">
                  <span className="text-slate-800 font-medium">
                    Representação:
                  </span>
                  <span className="text-slate-800 ml-1">
                    {passwordData.representation}
                  </span>
                </div>
                <div className="flex items-center">
                  <span className="text-slate-800 font-medium">
                    Categoria:
                  </span>
                  <span className="text-slate-800 ml-1">
                    {passwordData.passwords[0].category.categ_name}
                  </span>
                </div>
                <div className="flex items-center">
                  {item.quantity.map((q: any) => (
                    <div key={q.quant_id} className="flex items-center">
                      <span className="text-slate-800 font-medium">Valor:</span>
                      <span className="text-slate-800 ml-1">
                        {formatCurrency(q.quant_value)}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className="mt-4">
            <span className="text-slate-800 font-medium">
              Total: {formatCurrency(total)}
            </span>
          </div>
          <div className="flex flex-col gap-y-2 sm:gap-x-2 sm:flex-row sm:items-center mt-4">
            <div
              className="w-[158px] px-3 py-2 flex items-center gap-x-2 rounded-md hover:cursor-pointer bg-green-400"
              onClick={handleCreatePaymentPix}
            >
              <FaPix className="text-lg text-white" />
              <span className="text-white">
                Pagar com PIX
              </span>
            </div>

            <div
              className="w-[256px] px-3 py-2 flex items-center gap-x-2 rounded-md hover:cursor-pointer bg-blue-400 opacity-25"
              onClick={() => { }}
            >
              <FaCreditCard className="text-lg text-white" />
              <span className="text-white">
                Pagar com cartão de crédito
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  )
}