let isPagSeguroScriptLoaded = false;

const pagSeguroScript = document.createElement("script");
pagSeguroScript.setAttribute(
  "src",
  "https://assets.pagseguro.com.br/checkout-sdk-js/rc/dist/browser/pagseguro.min.js"
);

function loadPagSeguroScript(): Promise<void> {
  return new Promise((resolve, reject) => {
    if (isPagSeguroScriptLoaded) {
      // Se o script já foi carregado, resolve imediatamente
      resolve();
      return;
    }

    pagSeguroScript.onload = () => {
      console.log("Script do PagSeguro carregado com sucesso.");
      if (window.PagSeguro) {
        isPagSeguroScriptLoaded = true;
        resolve();
      } else {
        reject(new Error("PagSeguro não foi carregado."));
      }
    };

    pagSeguroScript.onerror = () => {
      reject(new Error("Erro ao carregar o script do PagSeguro."));
    };

    document.head.appendChild(pagSeguroScript);
  });
}

export async function encryptCardPagSeguro(cardData: any): Promise<string> {
  try {
    await loadPagSeguroScript();

    if (!window.PagSeguro) {
      throw new Error("PagSeguro não está disponível.");
    }

    const card = window.PagSeguro.encryptCard({
      publicKey:
        "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAr+ZqgD892U9/HXsa7XqBZUayPquAfh9xx4iwUbTSUAvTlmiXFQNTp0Bvt/5vK2FhMj39qSv1zi2OuBjvW38q1E374nzx6NNBL5JosV0+SDINTlCG0cmigHuBOyWzYmjgca+mtQu4WczCaApNaSuVqgb8u7Bd9GCOL4YJotvV5+81frlSwQXralhwRzGhj/A57CGPgGKiuPT+AOGmykIGEZsSD9RKkyoKIoc0OS8CPIzdBOtTQCIwrLn2FxI83Clcg55W8gkFSOS6rWNbG5qFZWMll6yl02HtunalHmUlRUL66YeGXdMDC2PuRcmZbGO5a/2tbVppW6mfSWG3NPRpgwIDAQAB",
      holder: cardData.holder,
      number: cardData.number,
      expMonth: cardData.expMonth,
      expYear: cardData.expYear,
      securityCode: cardData.securityCode,
    });
    return card;
  } catch (error) {
    console.error("Erro ao criptografar o cartão:", error);
    throw error;
  }
}
