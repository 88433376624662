import styled from "styled-components";

export const MyPasswordsStyled = styled("div")`
    border: solid 1px ${({theme}) => theme.border.primary_opacy};
    padding: 1.5em 2.5% 2em 2.5%;
    margin-top: -4em;
    background-color: ${({ theme }) => theme.background.secondary};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    width: 95%;
    overflow-x: hidden;

    .table-container {
        overflow-x: auto;
        width: 100%;
        margin-top: 1em;
        padding-bottom: 10px;

        &::-webkit-scrollbar {
            height: 12px;
            background-color: ${({theme}) => theme.background.secondary3};
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
            height: 12px;
            background-color: ${({theme}) => theme.background.primary};
            border-radius: 10px;
        }

        table {
            width: 100%;
            border-collapse: collapse;
            font-size: 1.2em;
            font-family: 'Arial', sans-serif;
            min-width: 400px;
            box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
            border-collapse: separate;
            border-spacing: 1px 1px;
            thead {
                tr {
                    color: ${({ theme }) => theme.color.secondary};
                    background-color: ${({ theme }) => theme.background.secondary3};
                    th {
                        div {
                            padding: 12px 10px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            font-weight: 500;
                        }
                    }
                }
            }
            tbody {
                tr {
                    background-color: ${({ theme }) => theme.background.primary};
                    border-radius: 10px;
                    color: ${({ theme }) => theme.color.light};

                    &:nth-of-type(even) {
                        color: ${({ theme }) => theme.color.dark};
                        background-color: ${({ theme }) => theme.background.secondary3};
                    }
                    td {
                        div {
                            align-items: center;
                            display: flex;
                            padding: 12px 15px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            svg {
                                margin-right: 5px;
                            }
                            a {
                                color: ${({ theme }) => theme.color.light};
                            }
                        }
                    }
                }
            }
        }
    }
`
